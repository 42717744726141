import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Style = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-xlarge);

  @media screen and (max-width: 834px) {
    grid-template-columns: 1fr;
    gap: var(--spacing-medium);
  }

  & > * {
    & > div >:last-child {
      margin-top: 0px;
    }

    @media not screen and (max-width: 834px) {
      &:nth-child(3) {
        grid-row: 1 / 3;
        grid-column: 2;
      }
    }

    @media screen and (max-width: 834px) {
      margin-left: calc(var(--spacing-small) * -1);
      margin-right: calc(var(--spacing-small) * -1);
    }
  }
`;

const SeasonsColumns = ({ children }) => (
  <Style>
    {children}
  </Style>
);

SeasonsColumns.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SeasonsColumns;
